import axios from 'axios'

import { type ServerResponse } from '~/axios'
import {
  type InterventionDefinition,
  type InterventionDefinitionWithOrganizations,
} from '~/utils/types/interventions'
import { type User } from '~/utils/types/user'

import { type Family } from './family-services'

export const deleteInterventionDefinitionById = async (
  interventionDefinitionId: number,
) => {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteInterventionDefinition($interventionDefinition: InterventionDefinitionModelInput) {deleteInterventionDefinition (interventionDefinition: $interventionDefinition){successful}}',
      variables: {
        interventionDefinition: {
          id: interventionDefinitionId,
        },
      },
    }),
  })
}

export const listInterventionsQuestions = async () =>
  await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query { interventionPresetQuestions { id codeName shortName answerType coreQuestion presetOptions } }',
    }),
  })

export async function getInterventionDefinitionsByUser() {
  type InterventionDefinitionByUserResponse = ServerResponse<{
    interventionsDefinitionByUser: InterventionDefinitionWithOrganizations[]
  }>

  const response = await axios.post<InterventionDefinitionByUserResponse>(
    '/graphql',
    {
      query: `query { interventionsDefinitionByUser { id title description organizations { id name } questions { id codeName shortName answerType coreQuestion required options {value text otherOption} } } }`,
    },
  )

  return response.data.data.interventionsDefinitionByUser
}

export const getInterventionDefinition = async (
  interventionDefinitionId: number,
) => {
  return await axios.post<
    ServerResponse<{
      retrieveInterventionDefinition: InterventionDefinition
    }>
  >('/graphql', {
    query:
      'query retrieveInterventionDefinition ($interventionDefinition: Long!) { retrieveInterventionDefinition(interventionDefinition: $interventionDefinition) { id title active questions { id codeName shortName answerType coreQuestion required options {value text otherOption}} } } ',
    variables: {
      interventionDefinition: interventionDefinitionId,
    },
  })
}

export interface InterventionsBySnapshot {
  id: number
  intervention: { id: number } | null
  massiveInterventionId: number | null
  interventionName: string
  interventionType: string
  interventionDate: number
  generalIntervention: boolean
  stoplightIndicator: string[]
  indicatorPrioritization?: any
  indicatorActionStatus?: any
  progressDescription?: any
  complyingActionPlan?: any
  improvingIndicators?: any
  identifiedProblem?: any
  improvementReason?: any
}

export const listInterventionsBySnapshot = async (
  snapshotId: number,
  params: string,
) =>
  await axios<
    ServerResponse<{ interventionsBySnapshot: InterventionsBySnapshot[] }>
  >({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: `query interventionsBySnapshot( $snapshot: Long!) { interventionsBySnapshot( snapshot: $snapshot){ id intervention{id} massiveInterventionId ${params}}}`,
      variables: {
        snapshot: snapshotId,
      },
    }),
  })

export const addOrUpdadteInterventionDefinition = async (
  user: User,
  definition,
  organizations,
) => {
  if (!definition.id) {
    return await axios({
      method: 'post',
      url: '/graphql',
      data: JSON.stringify({
        query:
          'mutation createInterventionDefinition($interventionDefinition: InterventionDefinitionModelInput,$organizations: [Long], $application: Long!) {createInterventionDefinition (interventionDefinition: $interventionDefinition,organizations: $organizations, application: $application){successful}}',
        variables: {
          interventionDefinition: definition,
          organizations,
          application: user.hub?.id ? user.hub.id : '',
        },
      }),
    })
  }
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation updateInterventionDefinition($interventionDefinition: InterventionDefinitionModelInput) {updateInterventionDefinition (interventionDefinition: $interventionDefinition){successful}}',
      variables: {
        interventionDefinition: definition,
      },
    }),
  })
}

export const deleteIntervention = async (definitionId: number) => {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteIntervention($intervention: Long) { deleteIntervention (intervention: $intervention) { successful } }',
      variables: {
        intervention: definitionId,
      },
    }),
  })
}

export const assignIntervention = async (
  user: User,
  interventionId: number,
  organizations,
) =>
  await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation assignInterventionDefinition($interventionDefinition: Long,$organizations: [Long]!, $application: Long!) {assignInterventionDefinition (interventionDefinition: $interventionDefinition,organizations: $organizations, application: $application){successful}}',
      variables: {
        interventionDefinition: interventionId,
        organizations,
        application: user.hub?.id ? user.hub.id : '',
      },
    }),
  })

export const createOrUpdateIntervention = async (
  values,
  interventionDefinition,
  snapshot,
  relatedIntervention,
  id: number,
  family,
  params,
) => {
  if (id) {
    return await axios({
      method: 'post',
      url: '/graphql',
      data: JSON.stringify({
        query: `mutation updateIntervention($intervention: InterventionDataModelInput) { updateIntervention (intervention: $intervention) { id  intervention{id} ${params} } }`,
        variables: {
          intervention: {
            id,
            values,
            interventionDefinition,
            snapshot,
            intervention: relatedIntervention,
          },
        },
      }),
    })
  }
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: `mutation createIntervention($intervention: InterventionDataModelInput, $client: String) { createIntervention (intervention: $intervention, client: $client) { id  intervention{id} ${params} } }`,
      variables: {
        intervention: {
          values,
          interventionDefinition,
          snapshot,
          intervention: relatedIntervention,
          family: family.familyId,
          familyName: family.familyName,
        },
        client: 'WEB',
      },
    }),
  })
}

export interface InterventionDataResponse {
  values: Array<{
    codeName: string
    value: string
    multipleValue: string[]
    multipleText: string[]
    other: boolean
  }>
}
export async function getInterventionById(interventionId: number) {
  return await axios.post<
    ServerResponse<{ retrieveInterventionData: InterventionDataResponse }>
  >('/graphql', {
    query:
      'query retrieveInterventionData ($intervention: Long!) { retrieveInterventionData(intervention: $intervention) { values { codeName value multipleValue multipleText other} } } ',
    variables: {
      intervention: interventionId,
    },
  })
}

export const interventionDefinitionByFamily = async (familyId: number) => {
  type InterventionDefinitionByFamilyResponse = ServerResponse<{
    interventionDefinitionByFamily: InterventionDefinition | null
  }>

  return await axios<InterventionDefinitionByFamilyResponse>({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query interventionDefinitionByFamily( $family: Long!) { interventionDefinitionByFamily(family: $family){ id title description active questions { id codeName shortName answerType coreQuestion required options {value text otherOption}} }}',
      variables: {
        family: familyId,
      },
    }),
  })
}

export async function createMassiveInterventions({
  familiesIds,
  intervention,
  surveyDefinitionId,
}: {
  familiesIds: number[]
  surveyDefinitionId: number
  intervention: {
    values: Array<
      | { codeName: string; value: string | number | boolean | null }
      | { codeName: string; multipleValue: string[]; multipleText: string[] }
    >

    interventionDefinition: number
  }
}) {
  const query = /* GraphQL */ `
    mutation createMassiveInterventions(
      $families: [Long]
      $surveyDefinitionId: Long
      $intervention: InterventionDataModelInput
    ) {
      createMassiveInterventions(
        families: $families
        intervention: $intervention
        surveyDefinition: $surveyDefinitionId
      ) {
        successful
      }
    }
  `

  return await axios<
    ServerResponse<{ createMassiveInterventions: { successful: boolean } }>
  >({
    method: 'post',
    url: '/graphql',
    data: {
      query,
      variables: {
        families: familiesIds,
        intervention,
        surveyDefinitionId,
      },
    },
  })
}

export async function listMassiveInterventions() {
  const query = /* GraphQL */ `
    query listMassiveInterventionsById {
      listMassiveInterventionsByUserId {
        id
        interventionName
        interventionDate
        interventionDefinitionId: interventionDefinition
      }
    }
  `

  return await axios<
    ServerResponse<{
      listMassiveInterventionsByUserId: Array<{
        id: number
        interventionName: string
        interventionDate: string
        interventionDefinitionId: number
      }>
    }>
  >({
    method: 'post',
    url: '/graphql',
    data: {
      query,
    },
  })
}

export async function massiveInterventionWithFamilies(
  massiveInterventionId: number,
  params: string[],
) {
  const query = /* GraphQL */ `
    query interventionWithFamilies($massiveInterventionId: Long!) {
      interventionWithFamilies(massiveInterventionId: $massiveInterventionId) {
        intervention { ${params.join('\n')} }
        families {
          familyId
          name
        }
      }
    }
  `
  return await axios<
    ServerResponse<{
      interventionWithFamilies: {
        intervention: Record<string, string | number | boolean | null>
        families: Family[]
      }
    }>
  >({
    url: '/graphql',
    method: 'post',
    data: {
      query,
      variables: { massiveInterventionId },
    },
  })
}

export async function deleteMassiveIntervention(massiveInterventionId: number) {
  const query = /* GraphQL */ `
    mutation deleteMassiveInterventions($massiveInterventionID: Long) {
      deleteMassiveInterventions(
        massiveInterventionID: $massiveInterventionID
      ) {
        successful
      }
    }
  `

  return await axios({
    url: '/graphql',
    method: 'post',
    data: {
      query,
      variables: { massiveInterventionID: massiveInterventionId },
    },
  })
}
