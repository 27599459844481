import { type ChangeEvent, type KeyboardEvent } from 'react'

import {
  Add as AddIcon,
  CloudUpload as CloudUploadIcon,
  ControlPoint as ControlPointIcon,
  HighlightOff as NotInterestedIcon,
} from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  InputAdornment,
  Radio,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import * as Excel from 'exceljs'
import { Virtuoso } from 'react-virtuoso'

import { SUPPORTED_EXCEL_MIME_TYPES } from '~/utils/allowedFileExtensions'

const textInputRegex = /["\\]/g

interface EconomicOptionFormOption {
  text: string
  value: string
  otherOption?: boolean

  // NOTE: Internal use only
  uniqueKey?: number
}

function focusInputAfterRender(
  inputsRefs: HTMLInputElement[],
  optionIndex: number,
) {
  setTimeout(() => {
    if (inputsRefs[optionIndex]) {
      inputsRefs[optionIndex].focus()
    }
  }, 0)
}

function preProcessOptionValue(option: EconomicOptionFormOption) {
  return {
    ...option,
    value: option.value.toUpperCase().trim().replace(/ +/g, '_'),
    text: option.text.trim().replace(/ +/g, ' '),
  }
}

interface EconomicOptionFormProps {
  options: EconomicOptionFormOption[]
  otherOption?: boolean

  setTouched?: () => void
  updateOptions: (options: EconomicOptionFormOption[]) => void
}

export default function EconomicOptionForm({
  options,
  otherOption = false,
  updateOptions,
  setTouched = () => {},
}: EconomicOptionFormProps) {
  const { t } = useTranslation()
  const inputsRefs = useRef<HTMLInputElement[]>([])

  const [otherValue, setOtherValue] = useState('')
  const [listHeight, setListHeight] = useState(0)

  useEffect(() => {
    const otherOption = options.find(option => option.otherOption)
    if (otherOption) {
      setOtherValue(otherOption.value)
    } else {
      setOtherValue('')
    }
  }, [options])

  function addOption(optionIndex?: number) {
    const allOptions = structuredClone(options)
    const createdAt = Date.now()

    if (typeof optionIndex === 'number') {
      const newIdxPosition = optionIndex + 1
      focusInputAfterRender(inputsRefs.current, newIdxPosition)

      allOptions.splice(newIdxPosition, 0, {
        value: '',
        text: '',
        uniqueKey: createdAt,
      })
    } else {
      focusInputAfterRender(inputsRefs.current, allOptions.length)
      allOptions.push({ value: '', text: '', uniqueKey: createdAt })
    }

    updateOptions(allOptions)
  }

  function updateOption(
    optionIndex: number,
    option: EconomicOptionFormOption,
    updateOtherValue: boolean,
  ) {
    const newOptions = updateOtherValue
      ? options.map(o => ({ ...o, otherOption: false }))
      : options
    newOptions[optionIndex] = {
      ...preProcessOptionValue(option),
      otherOption: updateOtherValue,
    }

    updateOptions(newOptions)
    updateOtherValue && setOtherValue(option.value)
  }

  function deleteOption(optionIndex: number) {
    const allOptions = structuredClone(options)
    allOptions.splice(optionIndex, 1)

    updateOptions(allOptions)
  }

  function handleKeyPress(
    event: KeyboardEvent<HTMLDivElement>,
    optionIdx: number,
  ) {
    if (event.key === 'Enter') {
      event.preventDefault()
      addOption(optionIdx)
    }
  }

  function handleExcelLoad(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0]
    const wb = new Excel.Workbook()
    const reader = new FileReader()
    const fileOptions: string[] = []
    setTouched()

    if (!file) return

    reader.readAsArrayBuffer(file)
    reader.onload = () => {
      const buffer = reader.result
      if (!buffer || typeof buffer === 'string') return

      try {
        void wb.xlsx.load(buffer).then(workbook => {
          workbook.eachSheet(sheet => {
            sheet.eachRow(row => {
              fileOptions.push(row.values[1] ? row.values[1].toString() : '')
            })
            const newOptions = [
              ...options,
              ...fileOptions.map(option => ({
                value: option.toUpperCase(),
                text: option,
              })),
            ]
            updateOptions(newOptions)
          })
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  return (
    <Stack gap={2}>
      <Divider>
        <Typography>{t('views.surveyBuilder.options')}</Typography>
      </Divider>

      <Box sx={{ height: listHeight, maxHeight: 400 }}>
        <Virtuoso
          data={options}
          increaseViewportBy={500}
          style={{ height: '100%' }}
          totalListHeightChanged={height => {
            setListHeight(height)
          }}
          itemContent={(optionIndex, option) => {
            const errorMessage = option.text.match(textInputRegex)

            return (
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                key={option.uniqueKey ?? option.value}
                defaultValue={option.text}
                inputRef={ref => {
                  if (ref) {
                    inputsRefs.current[optionIndex] = ref
                  }
                }}
                onKeyDown={e => {
                  handleKeyPress(e, optionIndex)
                }}
                onBlur={e => {
                  const value = e.target.value
                  if (value !== option.value) {
                    setTouched()

                    const newOption = { ...option, text: value, value }
                    updateOption(
                      optionIndex,
                      newOption,
                      option.value === otherValue && option.value !== '',
                    )
                  }
                }}
                error={!!errorMessage}
                inputProps={{ maxLength: '500' }}
                helperText={
                  errorMessage
                    ? t('validation.invalidCharacter', {
                        error: errorMessage,
                      })
                    : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Stack direction="row" alignItems="center">
                        {otherOption ? (
                          <Tooltip
                            arrow
                            placement="right"
                            title={t(
                              'views.surveyBuilder.economic.setAsOtherOption',
                            )}
                          >
                            <Radio
                              size="small"
                              value={option.value}
                              disabled={option.value.length === 0}
                              checked={
                                otherValue === option.value &&
                                option.value.length > 0
                              }
                              onChange={() => {
                                const newOption = {
                                  ...option,
                                  otherOption: false,
                                }
                                updateOption(optionIndex, newOption, true)
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            arrow
                            placement="left"
                            title={t('views.surveyBuilder.addOption')}
                          >
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() => {
                                addOption(optionIndex)
                              }}
                            >
                              <ControlPointIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        <Tooltip
                          arrow
                          placement="right"
                          title={t('general.delete')}
                        >
                          <IconButton
                            size="small"
                            color="error"
                            onMouseDown={() => {
                              deleteOption(optionIndex)
                            }}
                          >
                            <NotInterestedIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </InputAdornment>
                  ),
                }}
              />
            )
          }}
        />
      </Box>

      {options.length === 0 && (
        <Alert severity="error" variant="standard">
          {t('views.surveyBuilder.missingOptions')}
        </Alert>
      )}

      <ButtonGroup>
        <Tooltip
          arrow
          placement="left"
          title={t('views.surveyBuilder.uploadFileTooltip')}
        >
          <Button
            fullWidth
            component="label"
            variant="outlined"
            startIcon={<CloudUploadIcon />}
          >
            {t('views.surveyBuilder.uploadFile')}
            <input
              hidden
              type="file"
              accept={SUPPORTED_EXCEL_MIME_TYPES.join(', ')}
              onChange={e => {
                handleExcelLoad(e)
              }}
            />
          </Button>
        </Tooltip>

        <Button
          fullWidth
          startIcon={<AddIcon />}
          variant="outlined"
          onMouseDown={() => {
            addOption()
          }}
        >
          {t('views.surveyBuilder.addOption')}
        </Button>
      </ButtonGroup>
    </Stack>
  )
}
