import mime from 'mime'

// NOTE:
// For the list all official MIME types check
// https://www.iana.org/assignments/media-types/media-types.xhtml

export const SUPPORTED_IMAGE_MIME_TYPES = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/heic',
  'image/heif',
] as const

export const SUPPORTED_WORD_MIME_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
] as const

export const SUPPORTED_POWERPOINT_MIME_TYPES = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  'application/vnd.ms-powerpoint.template.macroEnabled.12',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
] as const

export const SUPPORTED_EXCEL_MIME_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
] as const

export const SUPPORTED_DOCUMENT_MIME_TYPES = [
  'application/pdf',
  ...SUPPORTED_WORD_MIME_TYPES,
  ...SUPPORTED_POWERPOINT_MIME_TYPES,
  ...SUPPORTED_EXCEL_MIME_TYPES,
] as const

export const SUPPORTED_AUDIO_MIME_TYPES = ['audio/mpeg'] as const

type ImageType = (typeof SUPPORTED_IMAGE_MIME_TYPES)[number]
type DocumentType = (typeof SUPPORTED_DOCUMENT_MIME_TYPES)[number]
type AudioType = (typeof SUPPORTED_AUDIO_MIME_TYPES)[number]
type WordType = (typeof SUPPORTED_WORD_MIME_TYPES)[number]
type PowerPointType = (typeof SUPPORTED_POWERPOINT_MIME_TYPES)[number]
type ExcelType = (typeof SUPPORTED_EXCEL_MIME_TYPES)[number]

function getAllExtensions(type: string) {
  return Array.from(mime.getAllExtensions(type) ?? [])
}

function addDot(extension: string) {
  return '.' + extension
}

export function toReactDropzoneSchema(
  types: Readonly<Array<ImageType | DocumentType | AudioType>>,
) {
  return types.reduce<
    Partial<{ [Key in ImageType | DocumentType | AudioType]: string[] }>
  >(
    (accumulator, value) => ({
      ...accumulator,
      [value]: getAllExtensions(value).map(addDot),
    }),
    {},
  )
}

export function getAllExtensionsForTypes(
  types: Readonly<
    Array<ImageType | DocumentType | WordType | PowerPointType | ExcelType>
  >,
) {
  return types.map(getAllExtensions).flat().map(addDot)
}
