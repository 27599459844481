import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import { type InterventionFormValues } from '~/screens/massive-interventions/MassiveInterventionForm'
import { type Family } from '~/services/family-services'
import { type CurrentSurvey } from '~/utils/types/current-survey'

export enum MassiveInterventionFormTab {
  Intervention = 'intervention',
  Families = 'families',
  Summary = 'summary',
}

interface MassiveInterventionFormSlice {
  families: Family[]
  form: InterventionFormValues | null
  surveyDefinition: CurrentSurvey | null
  currentTab: MassiveInterventionFormTab
}

const initialState: MassiveInterventionFormSlice = {
  form: null,
  families: [],
  surveyDefinition: null,
  currentTab: MassiveInterventionFormTab.Intervention,
}

const massiveInterventionFormSlice = createSlice({
  name: 'massiveInterventionFormSlice',
  initialState,
  reducers: {
    // Form
    updateInterventionForm: (
      state,
      action: PayloadAction<Partial<InterventionFormValues>>,
    ) => {
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      }
    },
    resetInterventionForm: state => {
      return { ...state, form: null }
    },

    // Survey
    setMassiveInterventionSurvey: (
      state,
      action: PayloadAction<CurrentSurvey>,
    ) => {
      return { ...state, surveyDefinition: action.payload }
    },
    clearMassiveInterventionSurvey: state => {
      return { ...state, surveyDefinition: null }
    },

    // Families
    updateFamilies: (state, action: PayloadAction<Family[]>) => {
      return { ...state, families: action.payload }
    },
    clearFamilies: state => ({ ...state, families: [] }),

    // Current Tab
    updateTab: (state, action: PayloadAction<MassiveInterventionFormTab>) => {
      return { ...state, currentTab: action.payload }
    },
    resetTabs: state => {
      return {
        ...state,
        currentTab: MassiveInterventionFormTab.Intervention,
      }
    },
  },
})

export const {
  updateInterventionForm,
  resetInterventionForm,

  setMassiveInterventionSurvey,
  clearMassiveInterventionSurvey,

  updateFamilies,
  clearFamilies,
  updateTab,
  resetTabs,
} = massiveInterventionFormSlice.actions
export default massiveInterventionFormSlice.reducer
