import { type ReactNode } from 'react'

import { Stack } from '@mui/material'
import { useTheme } from '@mui/styles'

import Footer from '~/Footer'
import Header from '~/Header'

export default function MainLayout({ children }: { children: ReactNode }) {
  const { spacing } = useTheme()
  const navbarHeight = spacing(8.5)

  return (
    <Stack sx={{ height: '100vh' }}>
      <Header />
      <Stack sx={{ flex: 1, marginTop: navbarHeight }}>{children}</Stack>
      <Footer />
    </Stack>
  )
}
