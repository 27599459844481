import { type PropsWithChildren } from 'react'

import { CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as Sentry from '@sentry/react'
import { HttpStatusCode } from 'axios'
import mixpanel from 'mixpanel-browser'
import { useSnackbar } from 'notistack'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { useTimeout } from 'usehooks-ts'

import { checkSessionToken } from '~/api'
import { updateUser } from '~/redux/slices/user'
import Login from '~/screens/Login'

import { axios } from './axios'
import PasswordWarningModal from './components/modals/PasswordWarningModal'
import { useAppDispatch, useAppSelector } from './redux/hooks'
import { validateDynamicLinkToken } from './services/dynamic-link'

function LoadingAuth() {
  const classes = useLoadingAuthStyles()

  return (
    <div className={classes.container}>
      <CircularProgress />
    </div>
  )
}

const useLoadingAuthStyles = makeStyles(_theme => ({
  container: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default function Authenticator({ children }: PropsWithChildren<object>) {
  const { t, i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const user = useAppSelector(state => state.user)

  const dispatch = useAppDispatch()
  const intercom = useIntercom()
  const navigate = useNavigate()
  const location = useLocation()

  const [authVerified, setAuthVerified] = useState(false)
  const [warningAlreadyShown, setWarningAlreadyShown] = useState(false)
  const [isRedirectingToLogin, setIsRedirectingToLogin] = useState(false)
  const [isCheckingDynamicLink, setIsCheckingDynamicLink] = useState(false)

  let warningTime = 39_600_000 // 11 hours before warning: 1000ms * 60s * 60m * 11hr = 39600000
  let expireTime = 41_400_000 // 11:30 hours before expiring: 1000ms * 60s * 60m * 11:30hr = 41400000

  const searchParam = useMemo(
    () => new URLSearchParams(location.search),
    [location],
  )

  /**
   * Used for user activation via email invitation with this shape:
   * @example
   * https://testing.povertystoplight.org/users/activate/xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
   */
  const isActivateUserScreen = location.pathname.includes('/activate/')

  /**
   * Used for taking surveys with just a link with this shape:
   * @example
   * http://testing.povertystoplight.org/?token=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
   */
  const isLoginScreen = location.pathname.includes('/login')
  const dynamicLinkToken = !isLoginScreen ? searchParam.get('token') : null
  const hasUserToken = !!user?.token

  const endSession = useCallback(() => {
    setAuthVerified(false)
    dispatch(updateUser(null))
    setIsRedirectingToLogin(false)
    setIsCheckingDynamicLink(false)
    navigate('/login', { replace: true })
  }, [dispatch, navigate])

  // Session check it's out of a useEffect because it needs to be done in every render
  if (!!user && !!user.sessionStart) {
    const timePassed = new Date().getTime() - user.sessionStart

    warningTime = warningTime - timePassed > 0 ? warningTime - timePassed : 0
    expireTime = expireTime - timePassed > 0 ? expireTime - timePassed : 0
  }

  useTimeout(() => {
    if (!hasUserToken) return
    enqueueSnackbar(t('views.login.expired'), { variant: 'error' })
    endSession()
  }, expireTime)

  useTimeout(() => {
    if (!hasUserToken) return
    if (!warningAlreadyShown) {
      enqueueSnackbar(t('views.login.warning'), { variant: 'warning' })
      setWarningAlreadyShown(true)
    }
  }, warningTime)

  useEffect(() => {
    if (!user?.token) return

    intercom.update({
      name: user.name,
      email: user.email,
      userId: user.username,
      createdAt: Math.floor(Date.now() / 1000),
      languageOverride: i18n.language,
    })
  }, [user, intercom, i18n])

  useEffect(() => {
    if (!user?.token) return

    mixpanel.identify(`${user.username}-${user.email}`)
    mixpanel.people.set({
      name: user.name,
      email: user.email,
      userName: user.username,
    })

    Sentry.setUser({
      id: user.id,
      username: user.username,
      email: user.email,
    })
  }, [user])

  useEffect(() => {
    if (!user?.token) return
    setAuthVerified(true)
  }, [user])

  useEffect(() => {
    if (dynamicLinkToken ?? user?.dynamicLink) return

    if (!user?.token) {
      setAuthVerified(false)
      return
    }

    if (!isRedirectingToLogin) return

    // Verifying token before logging user in
    checkSessionToken(user.token)
      .then(response => {
        dispatch(
          updateUser({
            permissions: response.data.permissions,
            username: response.data.username,
            id: response.data.userId,
            token: user.token,
            refreshToken: user.refreshToken,
            env: user.env,
            willExpire: user.willExpire,
            didExpire: user.didExpire,
            daysToExpirePassword: user.daysToExpirePassword,
            role: response.data.role,
            hub: response.data.application,
            organization: response.data.organization,
            stakeholder: response.data.stakeholder,
            name: response.data.name,
            email: response.data.email,
            phoneNumber: response.data.phoneNumber,
            interactive_help:
              !!response.data.application &&
              !!response.data.application.interactiveHelp,
            sessionExpired: false,
            sessionStart: user.sessionStart
              ? user.sessionStart
              : new Date().getTime(),
            dynamicLink: null,
          }),
        )
        setAuthVerified(true)
      })
      .catch(() => {
        endSession()
      })
  }, [dispatch, dynamicLinkToken, isRedirectingToLogin, endSession])

  useEffect(() => {
    const currentUrl = location.pathname

    if (currentUrl === '/' || currentUrl.includes('login')) {
      setIsRedirectingToLogin(false)
      return
    }
    setIsRedirectingToLogin(true)
  }, [location])

  useEffect(() => {
    if (!dynamicLinkToken) {
      return
    }

    if (user?.token && !user?.dynamicLink) {
      enqueueSnackbar(t('dynamicLink.warningUserLoggedIn'), {
        variant: 'warning',
      })

      return
    }

    async function checkDynamicLinkToken() {
      try {
        if (!dynamicLinkToken) return

        setIsCheckingDynamicLink(true)
        const data = await validateDynamicLinkToken(dynamicLinkToken)
        checkSessionToken(data.accessToken)
          .then(response => {
            dispatch(
              updateUser({
                ...user,
                permissions: response.data.permissions,
                username: response.data.username,
                id: response.data.userId,
                token: data.accessToken,
                // @ts-expect-error
                refreshToken: user?.refreshToken,
                env: data.env,
                role: response.data.role,
                hub: response.data.application,
                organization: response.data.organization,
                stakeholder: response.data.stakeholder,
                name: response.data.name,
                email: response.data.email,
                interactive_help:
                  !!response.data.application &&
                  !!response.data.application.interactiveHelp,
                sessionExpired: false,
                sessionStart: user?.sessionStart
                  ? user.sessionStart
                  : new Date().getTime(),
                dynamicLink: { ...data },
              }),
            )
            setAuthVerified(true)

            /**
             * Setting a specific header to identify all requests as coming
             * from a dynamic link
             *
             * **Note:** This requires a cleanup on logout
             */
            axios.defaults.headers.post['Stoplight-Client-Id'] =
              'stoplight-web-dynamic-link'
          })
          .catch(() => {
            endSession()
          })
          .finally(() => {
            setIsCheckingDynamicLink(false)
          })
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === HttpStatusCode.NotFound) {
            enqueueSnackbar(t('dynamicLink.errorSurveyNotFound'), {
              variant: 'error',
            })
            endSession()
            return
          }

          if (error.response?.status === HttpStatusCode.Unauthorized) {
            enqueueSnackbar('Token no longer valid', { variant: 'error' })
            endSession()
            return
          }
        }

        enqueueSnackbar(t('general.error'), { variant: 'error' })
        endSession()
      }
    }

    void checkDynamicLinkToken()
    // NOTE: For some reason `t` triggers 2 updates
    // NOTE: Cannot use `user` as dep because we're updating the user and using the `user` at the same time
  }, [dispatch, enqueueSnackbar, dynamicLinkToken, endSession])

  return (
    <>
      <PasswordWarningModal />

      {((authVerified && hasUserToken) || isActivateUserScreen) && (
        <>{children}</>
      )}
      {!authVerified &&
        !hasUserToken &&
        !isActivateUserScreen &&
        !isCheckingDynamicLink && <Login />}

      {((authVerified && !hasUserToken) || isCheckingDynamicLink) && (
        <LoadingAuth />
      )}
    </>
  )
}
