import { zodResolver } from '@hookform/resolvers/zod'
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Cancel as CancelIcon,
  Check as CheckIcon,
  Face as FaceIcon,
  People as PeopleIcon,
  PlaylistRemove as PlaylistRemoveIcon,
  PsychologyAlt as PsychologyAltIcon,
  Refresh as RefreshIcon,
  Save as SaveIcon,
} from '@mui/icons-material'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Alert,
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Switch,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { Container, Stack } from '@mui/system'
import Grid from '@mui/system/Unstable_Grid/Grid'
import {
  DataGrid,
  type GridColDef,
  type GridLocaleText,
  type GridRowSelectionModel,
} from '@mui/x-data-grid'
import { type Localization } from '@mui/x-data-grid/internals'
import {
  arSD,
  bgBG,
  enUS,
  esES,
  frFR,
  ptBR,
  ptPT,
  roRO,
  skSK,
} from '@mui/x-data-grid/locales'
import { DatePicker } from '@mui/x-date-pickers'
import * as Sentry from '@sentry/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { type VirtualItem, useVirtualizer } from '@tanstack/react-virtual'
import { type TFunction } from 'i18next'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form'
import {
  AutocompleteElement,
  CheckboxButtonGroup,
  SwitchElement,
  TextFieldElement,
} from 'react-hook-form-mui'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

import ConfirmationModal from '~/components/ConfirmationModal'
import FamiliesFilters from '~/components/FamiliesFilters'
import store from '~/redux'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { resetCurrentIntervention } from '~/redux/slices/currentIntervention'
import {
  MassiveInterventionFormTab,
  clearFamilies,
  resetInterventionForm,
  resetTabs,
  updateFamilies,
  updateInterventionForm,
  updateTab,
} from '~/redux/slices/massiveInterventionForm'
import { type Family, getFamiliesList } from '~/services/family-services'
import { createMassiveInterventions } from '~/services/interventions'
import { useRole } from '~/utils/hooks/useRole'
import { ROLES } from '~/utils/role-utils'
import { type PlatformLanguage } from '~/utils/types/i18n'
import { type InterventionDefinition } from '~/utils/types/interventions'

import { type FamilyFilterState } from '../Families'

const IMPROVING_INDICATORS_QUESTION = 'improvingIndicators'
const GENERAL_INTERVENTION_QUESTION = 'generalIntervention'
const STOPLIGHT_INDICATOR_QUESTION = 'stoplightIndicator'

export default function MassiveInterventionForm() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const surveyDefinition = useAppSelector(state => {
    const { surveyDefinition } = state.massiveInterventionForm

    if (!surveyDefinition) throw new Error('No survey definition')
    return surveyDefinition
  })
  const currentIntervention = useAppSelector(state => {
    if (!state.currentIntervention) throw new Error('No current intervention')
    return state.currentIntervention
  })
  const currentTab = useAppSelector(
    state => state.massiveInterventionForm.currentTab,
  )

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const handleTabChange = (newValue: MassiveInterventionFormTab) => {
    window.scrollTo(0, 0)
    dispatch(updateTab(newValue))
  }

  function handleInterventionFormSubmit(values: InterventionFormValues) {
    dispatch(updateInterventionForm(values))
    handleTabChange(MassiveInterventionFormTab.Families)
  }

  function handleFormCleanup() {
    dispatch(resetInterventionForm())
    dispatch(resetCurrentIntervention())
    dispatch(clearFamilies())
    dispatch(resetTabs())
  }

  const createMassiveInterventionMutation = useMutation({
    mutationFn: createMassiveInterventions,
    onSuccess: () => {
      enqueueSnackbar(
        t('views.familyProfile.interventions.form.save.success'),
        { variant: 'success' },
      )
      handleFormCleanup()
      navigate('/massive-interventions')
    },
    onError: error => {
      Sentry.captureException(error)
      enqueueSnackbar(t('views.familyProfile.interventions.form.save.error'), {
        variant: 'error',
      })
    },
  })

  function handleMassiveInterventionSubmit() {
    const { families, form, surveyDefinition } =
      store.getState().massiveInterventionForm

    if (!form) {
      enqueueSnackbar('Intervention form is empty. Please fill it out first', {
        variant: 'warning',
      })
      handleTabChange(MassiveInterventionFormTab.Intervention)
      return
    }

    if (!surveyDefinition) {
      enqueueSnackbar('Survey definition not found', {
        variant: 'error',
      })
      return
    }

    if (families.length === 0) {
      enqueueSnackbar('You need to add at least one family', {
        variant: 'warning',
      })
      handleTabChange(MassiveInterventionFormTab.Families)
      return
    }

    const preProcessedValues = Object.entries(form)
      .filter(([key]) => !key.includes('other-'))
      .map(([key, value]) => {
        const otherValueQuestionKey = `other-${key}`
        const question = currentIntervention.questions.find(
          q => q.codeName === key,
        )

        if (typeof value === 'object' && value && 'id' in value) {
          const otherValue = form[otherValueQuestionKey]
          const otherOption = question?.options?.find(o => o.otherOption)

          const hasOtherValue = !!otherOption && value.id === otherOption.value

          if (hasOtherValue && typeof otherValue === 'string') {
            return { codeName: key, value: otherValue }
          }

          return { codeName: key, value: value.id }
        }

        if (Array.isArray(value)) {
          const isCheckboxValue =
            value.length > 0 && value.every(v => typeof v === 'string')

          if (isCheckboxValue) {
            const castedValue = value as string[]

            const otherOption = question?.options?.find(o => o.otherOption)
            const hasOtherOption =
              !!otherOption && castedValue.includes(otherOption.value)

            if (hasOtherOption) {
              const otherValue = form[otherValueQuestionKey]

              if (typeof otherValue === 'string') {
                const valueWithOther = [...castedValue, otherValue]
                return { codeName: key, value: valueWithOther.join(', ') }
              }
            }

            return { codeName: key, value: castedValue.join(', ') }
          }

          const castedValue = value as Array<{ id: string; label: string }>
          return {
            codeName: key,
            multipleValue: castedValue.map(v => v.id),
            multipleText: castedValue.map(v => v.label),
          }
        }

        return { codeName: key, value: value ?? null }
      })

    createMassiveInterventionMutation.mutate({
      familiesIds: families.map(f => f.familyId),
      surveyDefinitionId: surveyDefinition.id,
      intervention: {
        values: preProcessedValues,
        interventionDefinition: currentIntervention.id,
      },
    })
  }

  return (
    <Container>
      <ConfirmationModal
        title={t('views.exitModal.cannotUndo')}
        subtitle={t('views.exitModal.confirmText')}
        open={isConfirmationModalOpen}
        confirmAction={() => {
          handleFormCleanup()
          navigate('/massive-interventions')
        }}
        onClose={() => {
          setIsConfirmationModalOpen(false)
        }}
      />

      <Stack gap={2} mt={4}>
        <Divider>
          <Typography sx={{ typography: { xs: 'h5', md: 'h4' } }}>
            {currentIntervention.title}
          </Typography>
        </Divider>
        <Typography textAlign="center" variant="subtitle1">
          {t('views.surveysFilter.label')}: {surveyDefinition.title}
        </Typography>

        <TabContext value={currentTab}>
          <TabList
            onChange={(_e, value: MassiveInterventionFormTab) => {
              handleTabChange(value)
            }}
            centered
            sx={{
              top: 0,
              zIndex: 10,
              position: 'sticky',
              bgcolor: theme => theme.palette.background.default,
            }}
          >
            <Tab
              label={t('views.massiveInterventions.intervention')}
              value={MassiveInterventionFormTab.Intervention}
              sx={{ textTransform: 'none' }}
              disabled={createMassiveInterventionMutation.isLoading}
            />

            <Tab
              label={t('views.families')}
              value={MassiveInterventionFormTab.Families}
              sx={{ textTransform: 'none' }}
              disabled={
                currentTab === MassiveInterventionFormTab.Intervention ||
                createMassiveInterventionMutation.isLoading
              }
            />

            <Tab
              label={t('views.massiveInterventions.summary')}
              value={MassiveInterventionFormTab.Summary}
              sx={{ textTransform: 'none' }}
              disabled={
                currentTab === MassiveInterventionFormTab.Intervention ||
                currentTab === MassiveInterventionFormTab.Families ||
                createMassiveInterventionMutation.isLoading
              }
            />
          </TabList>
          <TabPanel value={MassiveInterventionFormTab.Intervention}>
            <InterventionForm
              onSubmit={handleInterventionFormSubmit}
              onCancel={() => {
                setIsConfirmationModalOpen(true)
              }}
            />
          </TabPanel>

          <TabPanel value={MassiveInterventionFormTab.Families}>
            <FamiliesTable
              onPreviousPageClick={() => {
                handleTabChange(MassiveInterventionFormTab.Intervention)
              }}
              onNextPageClick={() => {
                handleTabChange(MassiveInterventionFormTab.Summary)
              }}
            />
          </TabPanel>

          <TabPanel value={MassiveInterventionFormTab.Summary}>
            <MassiveInterventionSummary
              onSubmit={handleMassiveInterventionSubmit}
              isSubmitting={createMassiveInterventionMutation.isLoading}
              onPreviousPage={() => {
                handleTabChange(MassiveInterventionFormTab.Families)
              }}
            />
          </TabPanel>
        </TabContext>
      </Stack>
    </Container>
  )
}

export type InterventionFormValues = z.infer<
  ReturnType<typeof createFormValidationSchema>
>
function createFormValidationSchema(
  t: TFunction,
  currentIntervention: InterventionDefinition,
) {
  const numberRegex = /^\d+$/

  const requiredMessage = t('validation.fieldIsRequired')
  const invalidNumber = t('validation.validNumber')
  const invalidDate = t('views.family.selectValidDate')

  const schema = currentIntervention.questions.map(question => {
    if (question.answerType === 'number') {
      const validation = question.required
        ? z
            .string()
            .min(1, requiredMessage)
            .regex(numberRegex, { message: invalidNumber })
        : z.union([
            z.literal(''),
            z.string().regex(numberRegex, { message: invalidNumber }),
            z.null(),
          ])

      return { key: question.codeName, value: validation }
    }

    if (question.answerType === 'boolean') {
      const validation = z.boolean()
      return { key: question.codeName, value: validation }
    }

    if (question.answerType === 'select') {
      const schema = z
        .object({ id: z.string(), label: z.string() })
        .nullable()
        .refine(
          value => {
            if (question.required && !value) return false
            return true
          },
          { message: requiredMessage },
        )
      const fieldValidation = question.required ? schema : schema.nullable()
      const hasOtherOption = question.options?.some(o => o.otherOption) ?? false

      if (hasOtherOption) {
        const otherOptionKey = `other-${question.codeName}`
        const otherFieldValidation = z.string().optional()

        return [
          { key: question.codeName, value: fieldValidation },
          { key: otherOptionKey, value: otherFieldValidation },
        ]
      }

      return [{ key: question.codeName, value: fieldValidation }]
    }

    if (question.answerType === 'multiselect') {
      const schema = z.object(
        { id: z.string(), label: z.string() },
        { invalid_type_error: requiredMessage },
      )
      const isQuestionRequired =
        question.required && question.codeName !== STOPLIGHT_INDICATOR_QUESTION

      const validation = isQuestionRequired
        ? z.array(schema).min(1, { message: requiredMessage })
        : z.array(schema)

      return { key: question.codeName, value: validation }
    }

    if (question.answerType === 'checkbox') {
      const validation = question.required
        ? z.array(z.string()).min(1, { message: requiredMessage })
        : z.array(z.string())

      const hasOtherOption = question.options?.some(o => o.otherOption) ?? false
      if (hasOtherOption) {
        const otherOptionKey = `other-${question.codeName}`
        const otherFieldValidation = z.string().optional()

        return [
          { key: question.codeName, value: validation },
          { key: otherOptionKey, value: otherFieldValidation },
        ]
      }

      return [{ key: question.codeName, value: validation }]
    }

    if (question.answerType === 'date') {
      const validation = z
        .number({ invalid_type_error: requiredMessage })
        .nullable()
        .transform<moment.Moment | null>(v => (v ? moment.unix(v) : null))
        .superRefine((value, ctx) => {
          if (question.required && !value) {
            ctx.addIssue({
              code: 'custom',
              message: requiredMessage,
            })
            return z.NEVER
          }

          if (question.coreQuestion) {
            const currentDate = moment()
            const isValid = !!value && value.isBefore(currentDate)
            if (!isValid) {
              ctx.addIssue({
                code: 'custom',
                message: invalidDate,
              })
              return z.NEVER
            }
          }
        })
        .transform(value => (value ? value.unix() : null))

      return { key: question.codeName, value: validation }
    }

    const validation = question.required
      ? z.string().min(1, { message: requiredMessage })
      : z.string()

    return { key: question.codeName, value: validation }
  })

  const flattenSchema = schema.flat(2)
  type SchemaValues = (typeof flattenSchema)[number]
  type FormValues = Record<string, SchemaValues['value']>

  const formValidationSchema = flattenSchema.reduce<FormValues>(
    (acc, currentValue) => {
      acc[currentValue.key] = currentValue.value
      return acc
    },
    {},
  )

  return z.object(formValidationSchema).superRefine((values, ctx) => {
    currentIntervention.questions.forEach(question => {
      const { codeName, answerType, options } = question
      const value = values[codeName]

      if (codeName === GENERAL_INTERVENTION_QUESTION) {
        const isGeneralIntervention = value
        const stoplightIndicatorValues = values[STOPLIGHT_INDICATOR_QUESTION]

        const isStoplightIndicatorEmpty =
          Array.isArray(stoplightIndicatorValues) &&
          stoplightIndicatorValues.length === 0

        if (!isGeneralIntervention && isStoplightIndicatorEmpty) {
          ctx.addIssue({
            message: requiredMessage,
            code: z.ZodIssueCode.custom,
            path: [STOPLIGHT_INDICATOR_QUESTION],
          })
        }
      }

      if (answerType === 'select') {
        if (
          typeof value !== 'object' ||
          Array.isArray(value) ||
          moment.isMoment(value)
        ) {
          return z.NEVER
        }
        const otherOption = options?.find(o => o.otherOption)
        const isOtherSelected =
          value && otherOption && value.id === otherOption.value

        if (isOtherSelected) {
          const otherValueQuestionKey = `other-${codeName}`
          const otherValueQuestion = values[otherValueQuestionKey]
          if (!otherValueQuestion) {
            ctx.addIssue({
              code: 'custom',
              message: requiredMessage,
              path: [otherValueQuestionKey],
            })
          }
        }
      }

      if (answerType === 'checkbox') {
        const otherOption = options?.find(o => o.otherOption)

        const isOtherSelected =
          Array.isArray(value) && value.some(v => v === otherOption?.value)

        if (isOtherSelected) {
          const otherValueQuestionKey = `other-${codeName}`
          const otherValueQuestion = values[otherValueQuestionKey]
          if (!otherValueQuestion) {
            ctx.addIssue({
              code: 'custom',
              message: requiredMessage,
              path: [otherValueQuestionKey],
            })
          }
        }
      }
    })
  })
}

interface InterventionFormProps {
  onCancel: () => void
  onSubmit: (values: Record<string, any>) => void
}

function InterventionForm({ onSubmit, onCancel }: InterventionFormProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const surveyDefinition = useAppSelector(state => {
    const { surveyDefinition } = state.massiveInterventionForm

    if (!surveyDefinition) throw new Error('No survey definition')
    return surveyDefinition
  })

  const currentIntervention = useAppSelector(state => {
    if (!state.currentIntervention) throw new Error('No current intervention')
    return state.currentIntervention
  })

  const validationSchema = useMemo(() => {
    const schema = createFormValidationSchema(t, currentIntervention)
    return schema
  }, [currentIntervention, t])

  const defaultValues = useRef(() => {
    const values = currentIntervention.questions.reduce<InterventionFormValues>(
      (acc, question) => {
        const otherValueQuestionKey = `other-${question.codeName}`

        const formValues = store.getState().massiveInterventionForm.form ?? {}
        const value = formValues[question.codeName]
        const otherValue = formValues[otherValueQuestionKey]

        if (otherValue) {
          acc[otherValueQuestionKey] = otherValue
        }

        if (
          question.answerType === 'text' ||
          question.answerType === 'number'
        ) {
          acc[question.codeName] = value ?? ''
          return acc
        }

        if (question.answerType === 'boolean') {
          acc[question.codeName] = value ?? false
          return acc
        }

        if (
          question.answerType === 'date' ||
          question.answerType === 'select'
        ) {
          acc[question.codeName] = value ?? null
          return acc
        }

        if (
          question.answerType === 'multiselect' ||
          question.answerType === 'checkbox'
        ) {
          acc[question.codeName] = value ?? []
          return acc
        }

        return acc
      },
      {},
    )

    return values
  })

  const form = useForm<InterventionFormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: defaultValues.current(),
    shouldUnregister: true,
  })

  const generalInterventionWatch = !!form.watch(GENERAL_INTERVENTION_QUESTION)

  useEffect(() => {
    const subscription = form.watch((value: InterventionFormValues) => {
      dispatch(updateInterventionForm(value))
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [form, dispatch])

  useEffect(() => {
    if (generalInterventionWatch) {
      form.setValue(STOPLIGHT_INDICATOR_QUESTION, [])
    }
  }, [generalInterventionWatch, form])

  function cleanValuesBeforeSubmit(data: InterventionFormValues) {
    currentIntervention.questions.forEach(question => {
      const { codeName, answerType } = question

      const value = data[codeName]
      if (answerType === 'date' && moment.isMoment(value)) {
        data[codeName] = value.unix()
      }
    })

    onSubmit(data)
  }

  return (
    <Card sx={{ p: 2 }} variant="outlined">
      <FormProvider {...form}>
        <Grid
          container
          noValidate
          spacing={2}
          component="form"
          onSubmit={form.handleSubmit(cleanValuesBeforeSubmit)}
        >
          {currentIntervention.questions.map(question => {
            const translation = t(
              `views.intervention.definition.questions.${question.codeName}.text`,
            )
            if (question.answerType === 'number') {
              return (
                <Grid xs={12} md={6} key={question.codeName}>
                  <TextFieldElement
                    fullWidth
                    label={translation}
                    name={question.codeName}
                    required={question.required}
                    inputProps={{ inputMode: 'numeric' }}
                    sx={{ bgcolor: theme => theme.palette.background.default }}
                  />
                </Grid>
              )
            }

            if (question.answerType === 'select') {
              if (!question.options) return null
              return (
                <Grid xs={12} md={6} key={question.codeName}>
                  <SelectWithOtherOtpion question={question} />
                </Grid>
              )
            }

            if (question.answerType === 'multiselect') {
              if (!question.options) return null

              const isRequired =
                question.codeName === STOPLIGHT_INDICATOR_QUESTION
                  ? question.required && !generalInterventionWatch
                  : question.required

              const disabled =
                question.codeName === STOPLIGHT_INDICATOR_QUESTION &&
                !!generalInterventionWatch

              const isSpecialQuestion = [
                IMPROVING_INDICATORS_QUESTION,
                STOPLIGHT_INDICATOR_QUESTION,
              ].includes(question.codeName)

              const options = isSpecialQuestion
                ? surveyDefinition.surveyStoplightQuestions.map(q => ({
                    id: q.codeName,
                    label: q.questionText,
                  }))
                : question.options.map(option => ({
                    id: option.value,
                    label: option.text,
                  }))

              return (
                <Grid xs={12} md={6} key={question.codeName}>
                  <AutocompleteElement
                    multiple
                    options={options}
                    label={translation}
                    name={question.codeName}
                    required={isRequired}
                    autocompleteProps={{ disabled }}
                    textFieldProps={{
                      fullWidth: true,
                      sx: {
                        bgcolor: theme => theme.palette.background.default,
                      },
                    }}
                  />
                </Grid>
              )
            }

            if (question.answerType === 'date') {
              return (
                <Grid xs={12} md={6} key={question.codeName}>
                  <Controller
                    control={form.control}
                    name={question.codeName}
                    render={({ field, fieldState }) => {
                      const value =
                        typeof field.value === 'number'
                          ? moment.unix(field.value)
                          : null

                      return (
                        <DatePicker
                          {...field}
                          value={value}
                          label={translation}
                          onChange={value => {
                            field.onChange(value ? moment(value).unix() : null)
                          }}
                          slotProps={{
                            field: { clearable: true },
                            textField: {
                              fullWidth: true,
                              error: fieldState.invalid,
                              required: question.required,
                              helperText: fieldState.error?.message,
                              sx: {
                                bgcolor: theme =>
                                  theme.palette.background.default,
                              },
                            },
                          }}
                        />
                      )
                    }}
                  />
                </Grid>
              )
            }

            if (question.answerType === 'checkbox') {
              if (!question.options) return null

              return (
                <Grid xs={12} key={question.codeName}>
                  <Box
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      bgcolor: theme => theme.palette.background.default,
                    }}
                  >
                    <CheckboxWithOtherOption question={question} />
                  </Box>
                </Grid>
              )
            }

            if (question.answerType === 'text') {
              return (
                <Grid xs={12} md={6} key={question.codeName}>
                  <TextFieldElement
                    fullWidth
                    label={translation}
                    name={question.codeName}
                    required={question.required}
                    sx={{ bgcolor: theme => theme.palette.background.default }}
                  />
                </Grid>
              )
            }

            if (question.answerType === 'boolean') {
              return (
                <Grid xs={12} md={6} key={question.codeName}>
                  <Box
                    sx={{
                      px: 2,
                      height: '100%',
                      display: 'flex',
                      borderRadius: 2,
                      alignItems: 'center',
                      bgcolor: theme => theme.palette.background.default,
                    }}
                  >
                    <SwitchElement
                      label={translation}
                      name={question.codeName}
                      switchProps={{
                        onChange: () => {
                          const isSpecialQuestion =
                            question.codeName === GENERAL_INTERVENTION_QUESTION

                          if (isSpecialQuestion && form.formState.isSubmitted) {
                            void form.trigger(STOPLIGHT_INDICATOR_QUESTION)
                          }
                        },
                      }}
                    />
                  </Box>
                </Grid>
              )
            }

            return null
          })}

          <Grid xs={12}>
            <Stack flexDirection="row" gap={2} justifyContent="center">
              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                disabled={form.formState.isSubmitting}
                onClick={onCancel}
              >
                {t('general.cancel')}
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                endIcon={<CheckIcon />}
                loading={form.formState.isSubmitting}
              >
                {t('general.save')}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </Card>
  )
}

interface FieldWithOtherOptionProps {
  question: InterventionDefinition['questions'][number]
}

function SelectWithOtherOtpion({ question }: FieldWithOtherOptionProps) {
  const otherValueQuestionKey = `other-${question.codeName}`
  const otherOption = question.options?.find(option => option.otherOption)

  const { t } = useTranslation()
  const translation = t(
    `views.intervention.definition.questions.${question.codeName}.text`,
  )

  const form = useFormContext<InterventionFormValues>()
  const selectWatch = form.watch(question.codeName)

  const isOtherSelected = useMemo(() => {
    if (Array.isArray(selectWatch)) return false
    if (!selectWatch || !otherOption) return false
    if (typeof selectWatch !== 'object') return false

    return selectWatch.id === otherOption.value
  }, [selectWatch, otherOption])

  useEffect(() => {
    if (!isOtherSelected) {
      form.setValue(otherValueQuestionKey, '')
    }
  }, [isOtherSelected, otherValueQuestionKey, form])

  return (
    <Stack gap={2} sx={{ bgcolor: theme => theme.palette.background.default }}>
      <AutocompleteElement
        label={translation}
        name={question.codeName}
        required={question.required}
        textFieldProps={{
          fullWidth: true,
          sx: {
            bgcolor: theme => theme.palette.background.default,
          },
        }}
        options={
          question.options?.map(option => ({
            id: option.value,
            label: option.text,
          })) ?? []
        }
      />

      {isOtherSelected && (
        <TextFieldElement
          required
          fullWidth
          label={t('views.survey.specifyOther')}
          name={otherValueQuestionKey}
        />
      )}
    </Stack>
  )
}

function CheckboxWithOtherOption({ question }: FieldWithOtherOptionProps) {
  const otherValueQuestionKey = `other-${question.codeName}`
  const otherOption = question.options?.find(option => option.otherOption)

  const { t } = useTranslation()
  const translation = t(
    `views.intervention.definition.questions.${question.codeName}.text`,
  )

  const form = useFormContext()
  const checkboxWatch = form.watch(question.codeName)

  const isOtherSelected =
    Array.isArray(checkboxWatch) &&
    checkboxWatch.some(v => v === otherOption?.value)

  useEffect(() => {
    if (!isOtherSelected) {
      form.setValue(otherValueQuestionKey, '')
    }
  }, [isOtherSelected, otherValueQuestionKey, form])

  return (
    <>
      <CheckboxButtonGroup
        row
        label={translation}
        name={question.codeName}
        required={question.required}
        options={
          question.options?.map(option => ({
            id: option.value,
            label: option.text,
          })) ?? []
        }
      />

      {isOtherSelected && (
        <TextFieldElement
          required
          fullWidth
          label={t('views.survey.specifyOther')}
          name={otherValueQuestionKey}
        />
      )}
    </>
  )
}

interface FamiliesTableProps {
  onPreviousPageClick: () => void
  onNextPageClick: () => void
}
function FamiliesTable({
  onNextPageClick,
  onPreviousPageClick,
}: FamiliesTableProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })

  const massiveInterventionSurvey = useAppSelector(state => {
    if (!state.massiveInterventionForm.surveyDefinition)
      throw new Error('No survey definition')
    return state.massiveInterventionForm.surveyDefinition
  })
  const massiveInterventionFamilies = useAppSelector(state => {
    return state.massiveInterventionForm.families
  })

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  })

  const [familyFilters, setFamilyFilters] = useState<FamilyFilterState>({
    name: '',
    hub: null,
    projects: [],
    facilitators: [],
    organizations: [],
  })

  const { userHasAnyRole } = useRole()
  const dispatch = useAppDispatch()
  const canAccessAnonymousFamily = userHasAnyRole([ROLES.ADMIN])

  const processedFilters = useMemo(() => {
    return {
      hubId: familyFilters.hub?.value ?? null,
      organizationIds: familyFilters.organizations.map(o => o.value),
      projectIds: familyFilters.projects.map(p => p.value),
      facilitatorIds: familyFilters.facilitators.map(f => f.value),
      name: familyFilters.name,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sortBy: null,
      sortDirection: null,
      surveyDefinitionId: massiveInterventionSurvey.id,
    }
  }, [familyFilters, paginationModel, massiveInterventionSurvey.id])

  const familiesQuery = useQuery({
    select: data => data.data.data.families,
    queryFn: async () => await getFamiliesList(processedFilters),
    queryKey: ['families', processedFilters, user],
  })

  const tableTranslations: Partial<GridLocaleText> = useMemo(() => {
    const mappedTranslations: Record<PlatformLanguage, Localization> = {
      en: enUS,
      es: esES,
      ar: arSD,
      bg: bgBG,
      pt: ptPT,
      ro: roRO,
      sk: skSK,
      ht: frFR,
      'pt-BR': ptBR,
    }

    const currentTranslation = mappedTranslations[language as PlatformLanguage]
    return currentTranslation.components.MuiDataGrid.defaultProps.localeText
  }, [language])

  const columns = useMemo<Array<GridColDef<Family>>>(() => {
    return [
      {
        width: 75,
        align: 'center',
        field: 'avatar',
        sortable: false,
        resizable: false,
        filterable: false,
        disableExport: true,
        hideSortIcons: true,
        headerName: 'Avatar',
        disableColumnMenu: true,
        renderHeader: () => null,
        renderCell: params => {
          const { countFamilyMembers, anonymous } = params.row

          if (countFamilyMembers > 1) {
            return (
              <Badge
                color="primary"
                overlap="circular"
                badgeContent={`+${countFamilyMembers - 1}`}
              >
                <Avatar>
                  <PeopleIcon />
                </Avatar>
              </Badge>
            )
          }

          if (anonymous) {
            return (
              <Avatar sx={{ display: 'inline-flex' }}>
                <PsychologyAltIcon />
              </Avatar>
            )
          }

          return (
            <Avatar sx={{ display: 'inline-flex' }}>
              <FaceIcon />
            </Avatar>
          )
        },
      },
      {
        flex: 1,
        minWidth: 150,
        field: 'name',
        headerName: t('views.familyList.familyName'),
        renderCell: params => (
          <Tooltip title={params.row.name} arrow>
            <Typography variant="body2" whiteSpace="wrap">
              {params.row.name}
            </Typography>
          </Tooltip>
        ),
      },
      {
        flex: 1,
        minWidth: 150,
        sortable: false,
        hideSortIcons: true,
        field: 'documentNumber',
        headerName: t('views.familyList.document'),
        renderCell: params => {
          if (params.row.anonymous && !canAccessAnonymousFamily) return null

          const label = `${params.row.documentTypeText}: ${params.row.documentNumber}`
          return (
            <Tooltip title={label} arrow>
              <Typography variant="body2" whiteSpace="wrap">
                {label}
              </Typography>
            </Tooltip>
          )
        },
      },
      {
        flex: 1,
        minWidth: 150,
        field: 'code',
        headerName: t('views.familyList.familyCode'),
        renderCell: params => {
          if (params.row.anonymous && !canAccessAnonymousFamily) return null

          return (
            <Tooltip title={params.row.code} arrow>
              <Typography whiteSpace="wrap" variant="body2">
                {params.row.code}
              </Typography>
            </Tooltip>
          )
        },
      },
    ]
  }, [t, canAccessAnonymousFamily])

  const rowCountRef = useRef<number>(familiesQuery.data?.totalElements ?? 0)
  const rowCount = useMemo(() => {
    const elements = familiesQuery.data?.totalElements
    if (elements !== undefined) {
      rowCountRef.current = elements
    }

    return rowCountRef.current
  }, [familiesQuery.data?.totalElements])

  function handleSelectedFamilies(familiesIds: GridRowSelectionModel) {
    const currentQueriedFamilies = familiesQuery.data?.content
    if (!currentQueriedFamilies) return

    const previouslySelectedFamilies = massiveInterventionFamilies.filter(f => {
      return familiesIds.includes(f.familyId)
    })
    const selectedFamilies = currentQueriedFamilies.filter(f => {
      return familiesIds.includes(f.familyId)
    })

    const allSelectedFamilies = [
      ...previouslySelectedFamilies,
      ...selectedFamilies,
    ]
    const uniqueSelectedFamilies = [...new Set(allSelectedFamilies)]

    dispatch(updateFamilies(uniqueSelectedFamilies))
  }

  return (
    <>
      <FamiliesFilters filters={familyFilters} setFilters={setFamilyFilters} />

      {familiesQuery.isError ? (
        <Alert
          sx={{ my: 2 }}
          severity="error"
          action={
            <Button
              color="error"
              variant="outlined"
              endIcon={<RefreshIcon />}
              onClick={() => {
                void familiesQuery.refetch()
              }}
            >
              {t('general.reload')}
            </Button>
          }
        >
          <Typography>{t('views.familyList.errorLoadingFamilies')}</Typography>
        </Alert>
      ) : (
        <Alert
          sx={{ my: 2 }}
          severity="success"
          action={
            <Button
              variant="outlined"
              endIcon={<PlaylistRemoveIcon />}
              disabled={massiveInterventionFamilies.length === 0}
              onClick={() => {
                dispatch(clearFamilies())
              }}
            >
              {t('views.familyList.removeAll')}
            </Button>
          }
        >
          <Typography>
            {t('views.familyProfile.massiveInterventions.familiesSelected', {
              count: massiveInterventionFamilies.length,
            })}
          </Typography>
        </Alert>
      )}

      <DataGrid
        autoHeight
        columns={columns}
        checkboxSelection
        rowCount={rowCount}
        paginationMode="server"
        disableRowSelectionOnClick
        keepNonExistentRowsSelected
        getRowHeight={() => 'auto'}
        getRowId={row => row.familyId}
        loading={familiesQuery.isLoading}
        rowSelectionModel={massiveInterventionFamilies.map(f => f.familyId)}
        onRowSelectionModelChange={handleSelectedFamilies}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        localeText={tableTranslations}
        rows={familiesQuery.data?.content ?? []}
        slots={{ loadingOverlay: () => <LinearProgress /> }}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sx={{
          '& .MuiDataGrid-cell': {
            minHeight: 65,
            display: 'flex',
            alignItems: 'center',
          },
        }}
      />

      <Stack flexDirection="row" gap={2} justifyContent="center" my={2}>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIosIcon />}
          onClick={() => {
            onPreviousPageClick()
          }}
        >
          {t('views.solutions.filter.previous')}
        </Button>
        <Button
          variant="outlined"
          endIcon={<ArrowForwardIosIcon />}
          disabled={massiveInterventionFamilies.length === 0}
          onClick={() => {
            onNextPageClick()
          }}
        >
          {t('views.solutions.filter.next')}
        </Button>
      </Stack>
    </>
  )
}

interface MassiveInterventionSummaryProps {
  isSubmitting: boolean

  onSubmit: () => void
  onPreviousPage: () => void
}

function MassiveInterventionSummary({
  isSubmitting,
  onPreviousPage,
  onSubmit,
}: MassiveInterventionSummaryProps) {
  const { t } = useTranslation()
  const intervention = useAppSelector(state => {
    if (!state.currentIntervention) throw new Error('No current intervention')
    return state.currentIntervention
  })

  const massiveInterventionForm = useAppSelector(state => {
    if (!state.massiveInterventionForm.form) {
      throw new Error('No massive intervention form')
    }
    return state.massiveInterventionForm.form
  })

  const massiveInterventionFamilies = useAppSelector(state => {
    return state.massiveInterventionForm.families
  })

  const familiesParentRef = useRef<HTMLDivElement>(null)

  const familyListVirtualizer = useVirtualizer({
    estimateSize: _idx => 75,
    count: massiveInterventionFamilies.length,
    getScrollElement: () => familiesParentRef.current,
  })

  return (
    <Grid container spacing={2}>
      <Grid xs={12} lg={6} container>
        <Typography
          variant="h5"
          sx={{ width: '100%', mb: 2 }}
          textAlign="center"
        >
          {t('views.familyProfile.massiveInterventions.formTitle')}
        </Typography>

        <Grid container sx={{ maxHeight: '60vh', overflow: 'auto' }}>
          {Object.entries(massiveInterventionForm).map(([key, value]) => {
            const question = intervention.questions.find(
              q => q.codeName === key,
            )
            if (!question) return null

            if (!value) return null
            if (Array.isArray(value) && value.length === 0) return null

            const { answerType, codeName, options } = question
            const translation = t(
              `views.intervention.definition.questions.${codeName}.text`,
            )

            if (answerType === 'boolean') {
              if (typeof value !== 'boolean') return null
              return (
                <Grid key={codeName} xs={12}>
                  <FormControlLabel
                    key={codeName}
                    label={translation}
                    control={<Switch checked={value} readOnly />}
                  />
                </Grid>
              )
            }

            if (answerType === 'date') {
              if (typeof value !== 'number') return null
              return (
                <Grid key={codeName} xs={12}>
                  <DatePicker
                    readOnly
                    key={codeName}
                    label={translation}
                    value={moment.unix(value)}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </Grid>
              )
            }

            if (answerType === 'multiselect') {
              if (!Array.isArray(value) || !options) return null
              const mappedOptions = options.map(o => ({
                label: o.text,
                id: o.value,
              }))

              return (
                <Grid key={codeName} xs={12}>
                  <Autocomplete
                    multiple
                    key={codeName}
                    readOnly
                    options={mappedOptions}
                    isOptionEqualToValue={(o, v) => o.id === v.id}
                    value={value as Array<{ id: string; label: string }>}
                    renderInput={params => (
                      <TextField {...params} label={translation} fullWidth />
                    )}
                  />
                </Grid>
              )
            }

            if (answerType === 'select') {
              const otherValueQuestionKey = `other-${codeName}`

              const otherValue = massiveInterventionForm[otherValueQuestionKey]

              return (
                <Grid key={codeName} xs={12}>
                  <Autocomplete
                    readOnly
                    isOptionEqualToValue={(o, v) => o.id === v.id}
                    value={value as { id: string; label: string }}
                    options={
                      options?.map(o => ({ id: o.value, label: o.text })) ?? []
                    }
                    renderInput={params => (
                      <TextField {...params} fullWidth label={translation} />
                    )}
                  />

                  {otherValue && (
                    <TextField
                      fullWidth
                      label={t('views.survey.specifyOther')}
                      value={otherValue}
                      InputProps={{ readOnly: true }}
                      sx={{ my: 2 }}
                    />
                  )}
                </Grid>
              )
            }

            if (answerType === 'checkbox') {
              if (!Array.isArray(value) || !options) return null
              const castedValue = value as string[]
              const mappedOptions = options.map(o => ({
                label: o.text,
                id: o.value,
              }))

              const otherValueQuestionKey = `other-${codeName}`
              const otherValue = massiveInterventionForm[otherValueQuestionKey]

              return (
                <Grid key={codeName} xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">{translation}</FormLabel>

                    <FormGroup row>
                      {mappedOptions.map(option => (
                        <FormControlLabel
                          key={option.id}
                          label={option.label}
                          control={
                            <Checkbox
                              readOnly
                              checked={castedValue.includes(option.id)}
                            />
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                  {otherValue && (
                    <TextField
                      fullWidth
                      sx={{ my: 2 }}
                      label={t('views.survey.specifyOther')}
                      value={otherValue}
                      InputProps={{ readOnly: true }}
                    />
                  )}
                </Grid>
              )
            }

            return (
              <Grid key={codeName} xs={12}>
                <TextField
                  fullWidth
                  value={value}
                  key={codeName}
                  name={codeName}
                  label={translation}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            )
          })}
        </Grid>
      </Grid>

      <Grid xs={12} sx={{ display: { xs: 'unset', lg: 'none' } }}>
        <Divider flexItem sx={{ my: 2 }} />
      </Grid>
      <Grid xs={12} lg={6} container>
        <Typography
          variant="h5"
          sx={{ width: '100%', mb: 2 }}
          textAlign="center"
        >
          {t('views.families')}
        </Typography>
        <Grid
          xs={12}
          ref={familiesParentRef}
          sx={{
            maxHeight: '60vh',
            overflow: 'auto',
            position: 'relative',
          }}
        >
          <List
            sx={{
              position: 'relative',
              height: `${familyListVirtualizer.getTotalSize()}px`,
            }}
          >
            {familyListVirtualizer.getVirtualItems().map(item => (
              <SummaryFamilyItem
                key={item.key}
                virtualItem={item}
                family={massiveInterventionFamilies[item.index]}
              />
            ))}
          </List>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Divider sx={{ my: 2 }} />

        <Stack flexDirection="row" gap={2} justifyContent="center">
          <Button
            variant="outlined"
            disabled={isSubmitting}
            onClick={onPreviousPage}
            startIcon={<ArrowBackIosIcon />}
          >
            {t('views.solutions.filter.previous')}
          </Button>

          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            onClick={onSubmit}
            endIcon={<SaveIcon />}
          >
            {t('general.save')}
          </LoadingButton>
        </Stack>
      </Grid>
    </Grid>
  )
}

interface SummaryFamilyItemProp {
  family: Family
  virtualItem: VirtualItem
}

function SummaryFamilyItem({ family, virtualItem }: SummaryFamilyItemProp) {
  const {
    anonymous,
    name,
    countFamilyMembers,
    documentNumber,
    documentTypeText,
  } = family

  return (
    <ListItem
      divider
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `${virtualItem.size}px`,
        transform: `translateY(${virtualItem.start}px)`,
      }}
    >
      <ListItemAvatar>
        {countFamilyMembers > 1 ? (
          <Badge
            color="primary"
            overlap="circular"
            badgeContent={`+${countFamilyMembers - 1}`}
          >
            <Avatar>
              <PeopleIcon />
            </Avatar>
          </Badge>
        ) : (
          <Avatar>{anonymous ? <PsychologyAltIcon /> : <FaceIcon />}</Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={`${documentTypeText}: ${documentNumber}`}
      />
    </ListItem>
  )
}
